import dayjs from 'dayjs';
import { createApi } from '@reduxjs/toolkit/query/react';
import { authBaseQuery } from '../utils/utils';

const prepareUrl = (state, endpoint, url) => {
  if (endpoint === 'getTicketTypesByEvent' || endpoint === 'getTickets') {
    return url;
  }

  const { id: eventID } = state.event;

  if (!eventID) {
    return {
      error: {
        status: 400,
        statusText: 'Bad Request',
        data: 'No event ID received',
      },
    };
  }

  return `ticket-types/events/${eventID}/${url}`;
};

const baseQueryV2 = authBaseQuery({
  baseUrl: process.env.REACT_APP_API_EVENT,
  prepareUrl,
});

const baseQueryV3 = authBaseQuery({
  baseUrl: process.env.REACT_APP_API_TEMP,
  prepareUrl,
});

export const showChannelsByUserID = [
  2887859, 3568296, 3788894, 3247503, 3728116, 995313,
];
export const showChannelsByEventID = [224481, 248177, 248178];

export const milestoneEventID = 248260;

const conditionalBaseQuery = async (args, api, extraOptions) => {
  const currentUrl = window.location.href;

  const foundEvent = showChannelsByEventID.some((id) =>
    currentUrl.includes(id.toString()),
  );

  const { auth, config } = api.getState();
  const currentUserId = config?.client?.id || auth?.user?.id;
  const foundUser = showChannelsByUserID.includes(currentUserId);

  const match = currentUrl.match(/\/event\/(\d+)/);
  const isAfterMilestoneEventID = match && +match[1] > milestoneEventID;

  const selectV3 =
    process.env.REACT_APP_SENTRY_ENVIRONMENT === 'production' &&
    (foundUser || foundEvent || isAfterMilestoneEventID);

  const selectedBaseQuery = selectV3 ? baseQueryV3 : baseQueryV2;
  return selectedBaseQuery(args, api, extraOptions);
};

export const ticketTypeApi = createApi({
  reducerPath: 'ticketTypesApi',
  baseQuery: conditionalBaseQuery,
  refetchOnMountOrArgChange: true,
  tagTypes: ['TicketType'],
  endpoints: (build) => ({
    getTicketTypes: build.query({
      query: (data) =>
        data ? 'ticket-types?relationship=true' : 'ticket-types',
      providesTags: (result) =>
        result
          ? [
            ...result.map(({ id }) => ({ type: 'TicketType', id })),
            'TicketType',
          ]
          : ['TicketType'],
    }),
    getTicketTypesByEvent: build.query({
      query: (event) => `ticket-types/events/${event}/ticket-types`,
      providesTags: (result) =>
        result
          ? [
            ...result.map(({ id }) => ({ type: 'TicketType', id })),
            'TicketType',
          ]
          : ['TicketType'],
    }),
    getTickets: build.mutation({
      query: (eventId) => ({
        url: `ticket-types/events/${eventId}/ticket-types`,
        method: 'GET',
      }),
    }),
    addTicketType: build.mutation({
      query: (body) => ({
        url: `ticket-types`,
        method: 'POST',
        body: {
          name: body.name,
          description: body.description,
          section: body.section,
          free: body.free,
          price: body.free ? 0 : +body.price,
          quantity: +body.quantity,
          max_per_sale: +body.max_per_sale,
          min_per_sale: +body.min_per_sale,
          start_date: dayjs(body.start_date).subtract(6, 'hour'),
          end_date: dayjs(body.end_date).subtract(6, 'hour'),
          label: body.label,
          status: body.status,
          sales_channel_name: body.sales_channel_name,
        },
      }),
      invalidatesTags: ['TicketType'],
    }),
    addTicketTypeWithOutMap: build.mutation({
      query: (body) => ({
        url: `ticket-types`,
        method: 'POST',
        body: {
          name: body.name,
          description: body.description,
          free: body.free,
          price: body.free ? 0 : +body.price,
          quantity: +body.quantity,
          max_per_sale: +body.max_per_sale,
          min_per_sale: +body.min_per_sale,
          start_date: dayjs(body.start_date).subtract(6, 'hour'),
          end_date: dayjs(body.end_date).subtract(6, 'hour'),
          label: body.label,
          status: body.status || 'active',
          sales_channel_name: body.sales_channel_name,
        },
      }),
      invalidatesTags: ['TicketType'],
    }),
    getTicketType: build.query({
      query: (id) => `ticket-types/${id}`,
      providesTags: (result, error, id) => [{ type: 'TicketType', id }],
    }),

    // usePatchTicketTypeMutation
    patchTicketType: build.mutation({
      query: ({ id, ...patch }) => ({
        url: `ticket-types/${id}`,
        method: 'PATCH',
        body: {
          name: patch.name,
          description: patch.description,
          section: patch.section,
          free: patch.free,
          price: patch.free ? 0 : +patch.price,
          quantity: +patch.quantity,
          max_per_sale: +patch.max_per_sale,
          min_per_sale: +patch.min_per_sale,
          start_date: patch.start_date,
          end_date: patch.end_date,
          status: patch.status || 'active',
          label: patch.label,
          sales_channel_name: patch.sales_channel_name,
        },
      }),
      invalidatesTags: ['TicketType'],
    }),
    // usePutTicketTypeMutation
    putTicketType: build.mutation({
      query: ({ id, ...patch }) => ({
        url: `ticket-types/${id}`,
        method: 'PUT',
        body: {
          name: patch.name,
          description: patch.description,
          section: patch.section,
          free: patch.free,
          price: patch.free ? 0 : +patch.price,
          quantity: +patch.quantity,
          max_per_sale: +patch.max_per_sale,
          min_per_sale: +patch.min_per_sale,
          start_date: patch.start_date,
          end_date: patch.end_date,
          status: patch.status || 'active',
          label: patch.label,
        },
      }),
      invalidatesTags: ['TicketType'],
    }),
    deleteTicketType: build.mutation({
      query(id) {
        return {
          url: `ticket-types/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['TicketType'],
    }),
    getTicketTypeByHoldId: build.query({
      query: (id) => `holdgroups/${id}/ticket-types`,
      providesTags: (result, error, id) => [{ type: 'TicketTypeByHoldID', id }],
    }),
    updateTicketStatus: build.mutation({
      query: ({ ticketId, status }) => ({
        url: `ticket-types/${ticketId}/status/${status}`,
        method: 'PUT',
      }),
      invalidatesTags: ['TicketType'],
    }),
  }),
});

export const {
  useGetTicketTypeQuery,
  useGetTicketTypesByEventQuery,
  useGetTicketTypesQuery,
  useAddTicketTypeMutation,
  useAddTicketTypeWithOutMapMutation,
  usePatchTicketTypeMutation,
  usePutTicketTypeMutation,
  useDeleteTicketTypeMutation,
  useGetTicketTypeByHoldIdQuery,
  useUpdateTicketStatusMutation,
  useGetTicketsMutation,
} = ticketTypeApi;

export const channels = {
  1: 'all',
  2: 'web',
  3: 'insiders',
};
