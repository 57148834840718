import { createSlice } from '@reduxjs/toolkit';
import { reHydrateStore } from '../utils/local-storage';

const initialState = {
  alerts: {
    checkin: true,
  },
  onBoarding: {
    modal: true,
  },
  tour: {
    event: true,
    admin: true,
  },
  freezeNps: null,
  client: null,
  merge: {
    id: null,
    in_progress: false,
  },
  unmerge: {
    ids: [],
    in_progress: false,
  },
};

const slice = createSlice({
  name: 'config',
  initialState: reHydrateStore('config') || initialState,
  reducers: {
    setConfig: (state, { payload }) => payload,
    setAlerts: (state, { payload }) => ({
      ...state,
      alerts: { ...state.alerts, ...payload },
    }),
    setOnBoarding: (state, { payload }) => ({
      ...state,
      onBoarding: { ...state.onBoarding, ...payload },
    }),
    setTour: (state, { payload }) => ({
      ...state,
      tour: { ...state.tour, ...payload },
    }),
    setFreezeNps: (state, { payload }) => ({
      ...state,
      freezeNps: payload,
    }),
    setClient: (state, { payload }) => ({ ...state, client: payload }),
    clearClient: (state) => ({ ...state, client: null }),
    setMerge: (state, { payload }) => ({
      ...state,
      merge: {
        id: payload.id,
        in_progress: payload.in_progress,
      },
    }),
    setUnmerge: (state, { payload }) => ({
      ...state,
      unmerge: {
        ids: payload.ids,
        in_progress: payload.in_progress,
      },
    }),
  },
});

export const {
  setConfig,
  setAlerts,
  setOnBoarding,
  setTour,
  setFreezeNps,
  setClient,
  clearClient,
  setMerge,
  setUnmerge,
} = slice.actions;

export default slice.reducer;

export const selectConfig = (state) => state.config;
export const selectConfigAlerts = (state) => state.config.alerts;
export const selectConfigOnBoarding = (state) => state.config.onBoarding;
export const selectConfigTour = (state) => state.config.tour;
export const selectFreezeNps = (state) => state.config.freezeNps;
export const selectConfigClient = (state) => state.config.client;
export const selectConfigMerge = (state) => state.config.merge;
export const selectConfigUnmerge = (state) => state.config.unmerge;
