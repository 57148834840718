import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  MobileStepper,
  Modal,
  Box,
  Button,
  IconButton,
  Typography,
} from '@mui/material';
import { CloseIcon } from '@boletia/blt-components/src/Icons';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import useLocales from '../../hooks/useLocales';

const StepperModal = ({ steps, run, callback }) => {
  const [open, setOpen] = useState(true);
  const [activeStep, setActiveStep] = useState(0);
  const { translate } = useLocales();

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      callback({ action: 'skip' });
      return setOpen(false);
    }
    return setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    run && (
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        sx={{ '.MuiBackdrop-root': { background: 'rgba(0, 0, 0, 0.5)' } }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: { xs: '85%', sm: '550px' },
            minHeight: 50,
            borderRadius: '12px',
            padding: '10px 8px 8px 10px',
            background: '#6E39D3',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <IconButton
              onClick={() => {
                callback({ action: 'skip' });
                setOpen(false);
              }}
            >
              <CloseIcon sx={{ fontSize: '12px', color: '#fff' }} />
            </IconButton>
          </Box>
          <Box
            sx={{
              color: '#fff',
              width: '90%',
              paddingLeft: '40px',
              marginBottom: '10px',
              fontSize: '16px',
            }}
          >
            <Typography sx={{ fontWeight: '600', marginBottom: '12px' }}>
              {steps[activeStep].title}
            </Typography>
            <Typography sx={{}}>{steps[activeStep].content}</Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <MobileStepper
              variant="dots"
              steps={steps.length}
              position="static"
              activeStep={activeStep}
              nextButton={
                <Button
                  size="small"
                  onClick={handleNext}
                  sx={{
                    color: '#fff',
                  }}
                >
                  {activeStep === steps.length - 1
                    ? translate('onboarding.modalButtons.finish')
                    : translate('onboarding.modalButtons.next')}
                  <KeyboardArrowRight />
                </Button>
              }
              backButton={
                <Button
                  size="small"
                  onClick={handleBack}
                  disabled={activeStep === 0}
                  sx={{
                    color: '#fff',
                  }}
                >
                  <KeyboardArrowLeft />
                  {translate('onboarding.modalButtons.back')}
                </Button>
              }
              sx={{
                'maxWidth': '90%',
                'flexGrow': 1,

                'background': 'transparent',
                '& .MuiMobileStepper-dot': {
                  background: 'rgba(255, 255, 255, 0.4)',
                },
                '& .MuiMobileStepper-dotActive': {
                  background: 'white',
                },
              }}
            />
          </Box>
        </Box>
      </Modal>
    )
  );
};

StepperModal.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.object).isRequired,
  run: PropTypes.bool.isRequired,
  callback: PropTypes.func.isRequired,
};

export default StepperModal;
