import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { LoadingScreen } from '@boletia/blt-ui';
import { Navigate, useLocation } from 'react-router';
import useEvent from '../hooks/useEvent';

export default function RequireV2({ children }) {
  const { is_in_checkout_v2: typeV2 } = useEvent();
  const [component, setComponent] = useState();
  const location = useLocation();

  useEffect(() => {
    if (typeV2 === undefined) {
      setComponent(<LoadingScreen />);
    } else if (typeV2) {
      setComponent(children);
    } else {
      setComponent(
        <Navigate
          to={'/dashboard/events/'}
          state={{ from: location }}
          replace
        />,
      );
    }
  }, [typeV2, children]);

  return component;
}

RequireV2.propTypes = {
  children: PropTypes.node.isRequired,
};
