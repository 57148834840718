import { useNavigate } from 'react-router';
import { Button, Grid, Typography } from '@mui/material';
import { LogoutIcon } from '@boletia/blt-components/src/Icons';
import { useDispatch } from 'react-redux';
import useUserClient from '../../../hooks/useUserClient';
import useLocales from '../../../hooks/useLocales';
import { boostApi } from '../../../store/api/boost.api';

const SuperAdminExit = () => {
  const { translate } = useLocales();
  const { clearClient } = useUserClient();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleOnClick = () => {
    clearClient();
    dispatch(
      boostApi.util.invalidateTags([
        'budget',
        'transactions',
        'campaigns',
        'campaign',
        'ad',
        'organizers',
      ]),
    );
    navigate('/');
  };

  return (
    <Button
      onClick={handleOnClick}
      sx={{
        'height': '50px',
        'backgroundColor': '#f5f0f0',
        'color': '#D44333',
        'borderRadius': '0 0 12px 12px',
        'padding': '6px 20px',
        'borderTop': '1px dashed #D44333',
        '&:hover': {
          backgroundColor: '#f3d2d2',
        },
      }}
    >
      <Grid container>
        <Grid item xs textAlign="left" display="flex" alignItems="center">
          <LogoutIcon sx={{ transform: 'rotate(180deg)', fontSize: '16px' }} />
        </Grid>
        <Grid item xs={6} alignSelf="center" textAlign="center">
          <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>
            {translate('superAdmin.exit')}
          </Typography>
        </Grid>
        <Grid item xs={3} />
      </Grid>
    </Button>
  );
};

export default SuperAdminExit;
