import { createApi } from '@reduxjs/toolkit/query/react';
import { authBaseQuery } from '../utils/utils';

export const checkpointsApi = createApi({
  reducerPath: 'checkpointsApi',
  baseQuery: authBaseQuery({
    baseUrl: process.env.REACT_APP_API_EVENT,
  }),
  tagTypes: ['checkpoints'],
  endpoints: (build) => ({
    getEventCheckpoints: build.query({
      query: (eventId) => `/checkpoints/v2/events/${eventId}`,
      providesTags: ['checkpoints'],
    }),
    createEventCheckpoint: build.mutation({
      query: (data) => ({
        url: `/checkpoints/events/${data.eventId}`,
        method: 'POST',
        body: data.body,
      }),
      invalidatesTags: (result, error, { eventId }) => ({
        type: 'checkpoints',
        id: eventId,
      }),
    }),
    getCheckpoints: build.query({
      query: (event) => `checkpoints/events/${event}`,
      keepUnusedDataFor: 0,
      providesTags: (result, error, id) => [{ type: 'Checkpoints', id }],
    }),
    getCheckpointById: build.query({
      query: (data) =>
        `checkpoints/events/${data.event}/checkpoint/${data.checkpoint}`,
      providesTags: (result, error, id) => [{ type: 'Checkpoints', id }],
    }),
    addCheckpoint: build.mutation({
      query: (data) => ({
        url: `checkpoints/events/${data.eventId}`,
        method: 'POST',
        body: data.body,
      }),
      invalidatesTags: [{ type: 'Checkpoints', id: 'LIST' }],
    }),
    updateCheckpoint: build.mutation({
      query: (data) => ({
        url: `checkpoints/events/${data.eventId}/checkpoint/${data.checkpointId}`,
        method: 'PUT',
        body: data.body,
        providesTags: (result, error, id) => [{ type: 'Checkpoints', id }],
      }),
    }),
    updateCheckpointName: build.mutation({
      query: (data) => ({
        url: `checkpoints/events/${data.eventId}/checkpoint/${data.checkpointId}/name`,
        method: 'PATCH',
        body: data.body,
        providesTags: (result, error, id) => [{ type: 'Checkpoints', id }],
      }),
    }),
    addTicketToCheckpoint: build.mutation({
      query: (data) => ({
        url: `checkpoints/events/${data.event}/checkpoint/${data.checkpoint}/ticket-type/${data.ticket}`,
        method: 'PATCH',
        invalidatesTags: [{ type: 'Checkpoints', id: 'LIST' }],
      }),
    }),
    addCheckpointTickets: build.mutation({
      query: (data) => ({
        url: `checkpoints/events/${data.event}/checkpoint/${data.checkpoint}/ticket-type/`,
        method: 'PATCH',
        body: data.body,
        invalidatesTags: [{ type: 'Checkpoints', id: 'LIST' }],
      }),
    }),
    addStaffer: build.mutation({
      query: (data) => ({
        url: `checkpoints/events/${data.eventId}/checkpoint/${data.checkpoint}/user/${data.staffId}`,
        method: 'PATCH',
        providesTags: (result, error, id) => [{ type: 'Checkpoints', id }],
      }),
    }),
    addCheckpointStaffers: build.mutation({
      query: (data) => ({
        url: `checkpoints/events/${data.eventId}/checkpoint/${data.checkpoint}/user/`,
        method: 'PATCH',
        body: data.body,
        providesTags: (result, error, id) => [{ type: 'Checkpoints', id }],
      }),
    }),
    deleteTicketToCheckpoint: build.mutation({
      query: (data) => ({
        url: `checkpoints/events/${data.event}/checkpoint/${data.checkpoint}/ticket-type/${data.ticket}`,
        method: 'DELETE',
        invalidatesTags: [{ type: 'Checkpoints', id: 'LIST' }],
      }),
    }),
    deleteCheckpoint: build.mutation({
      query: (data) => ({
        url: `checkpoints/events/${data.event}/checkpoint/${data.checkpoint}${data.query}`,
        method: 'DELETE',
        providesTags: (result, error, id) => [{ type: 'Checkpoints', id }],
      }),
    }),
    deleteAllCheckpoints: build.mutation({
      query: (data) => ({
        url: `checkpoints/events/${data.event}`,
        method: 'DELETE',
        providesTags: (result, error, id) => [{ type: 'Checkpoints', id }],
      }),
    }),
    deleteStaffer: build.mutation({
      query: (data) => ({
        url: `/checkpoints/events/${data.eventId}/checkpoint/${data.checkpoint}/user/${data.staffId}`,
        method: 'DELETE',
        providesTags: (result, error, id) => [{ type: 'Checkpoints', id }],
      }),
    }),
    deleteCheckpointByEventId: build.mutation({
      query: (data) => ({
        url: `checkpoints/events/${data.event}/checkpoint/${data.checkpoint}`,
        method: 'DELETE',
        invalidatesTags: [{ type: 'Checkpoints', id: 'LIST' }],
      }),
    }),
    deleteCheckpointByTicketId: build.mutation({
      query: (data) => ({
        url: `checkpoints/events/${data.event}/checkpoint/${data.checkpoint}/ticket-type/${data.ticket}`,
        method: 'DELETE',
        invalidatesTags: [{ type: 'Checkpoints', id: 'LIST' }],
      }),
    }),
  }),
});

export const {
  useGetEventCheckpointsQuery,
  useCreateEventCheckpointMutation,
  useDeleteCheckpointMutation,
  useGetCheckpointsQuery,
  useGetCheckpointByIdQuery,
  useAddCheckpointMutation,
  useUpdateCheckpointMutation,
  useUpdateCheckpointNameMutation,
  useAddTicketToCheckpointMutation,
  useAddCheckpointTicketsMutation,
  useDeleteTicketToCheckpointMutation,
  useDeleteAllCheckpointsMutation,
  useDeleteCheckpointByEventIdMutation,
  useDeleteCheckpointByTicketIdMutation,
  useAddStafferMutation,
  useAddCheckpointStaffersMutation,
  useDeleteStafferMutation,
} = checkpointsApi;

export const externalCheckpointsApi = createApi({
  reducerPath: 'externalCheckpoints',
  baseQuery: authBaseQuery({ baseUrl: process.env.REACT_APP_API_CHECKIN }),
  tagTypes: ['ExternalCheckpoints'],
  endpoints: (build) => ({
    getAttendees: build.mutation({
      query: (data) => ({
        url: `v1/events/attendees?event_id=${data.eventId}${
          data?.cursor ? `&cursor=${data.cursor}` : ''
        }${data.q ? `&q=${data.q}` : ''}${
          data.limit ? `&limit=${data.limit}` : ''
        }`,
        method: 'GET',
      }),
      providesTags: (result, error, id) => [{ type: 'Checkpoints', id }],
    }),
    postAccess: build.mutation({
      query: (data) => ({
        url: `v1/events/access/checks`,
        method: 'POST',
        body: {
          access_code: data?.accessCode,
          event_id: data?.eventId,
          checkpoint_id: data?.checkpointId,
        },
      }),
      invalidatesTags: [{ type: 'Checkpoints', id: 'LIST' }],
    }),
    deleteAccess: build.mutation({
      query: (data) => ({
        url: `v1/events/access/checks?access_code=${data.accessCode}&event_id=${data.eventId}&checkpoint_id=${data.checkpointId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'Checkpoints', id: 'LIST' }],
    }),
    patchAccess: build.mutation({
      query: ({ token }) => ({
        url: `v1/events/access/checks?token=${token}`,
        method: 'PATCH',
      }),
      invalidatesTags: [{ type: 'Checkpoints', id: 'LIST' }],
    }),
    getRecords: build.mutation({
      query: (data) => ({
        url: `v1/events/actions?event_id=${data.eventId}${
          data?.cursor ? `&cursor=${data.cursor}` : ''
        }${data.q ? `&q=${data.q}` : ''}${
          data.limit ? `&limit=${data.limit}` : ''
        }`,
        method: 'GET',
      }),
      invalidatesTags: [{ type: 'Checkpoints', id: 'LIST' }],
    }),
    postMerge: build.mutation({
      query: (data) => ({
        url: `v1/events/parents?parent_event_id=${data.parentId}&event_ids=${data.eventIds}`,
        method: 'POST',
      }),
      invalidatesTags: [{ type: 'Checkpoints', id: 'LIST' }],
    }),
    deleteMerge: build.mutation({
      query: (data) => ({
        url: `v1/events/parents?event_ids=${data.eventIds}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'Checkpoints', id: 'LIST' }],
    }),
    // useGetMergeMutation
    getMerge: build.mutation({
      query: ({ eventID }) => ({
        url: `v1/events/${eventID}/merge`,
        method: 'GET',
      }),
      invalidatesTags: [{ type: 'Checkpoints', id: 'LIST' }],
    }),
    getStatistics: build.query({
      query: (data) => ({
        url: `v1/events/stats?event_id=${data.eventId}`,
        method: 'GET',
      }),
      providesTags: ['getStatistics'],
    }),
    getStats: build.query({
      query: (data) => ({
        url: `v1/events/stats/lists?event_ids=${data.eventId}`,
        method: 'GET',
      }),
      providesTags: (result, error, id) => [{ type: 'Checkpoints', id }],
    }),
    getRelationships: build.query({
      query: (data) => ({
        url: `/v1/events/relationships?event_id=${data.eventId}`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetAttendeesMutation,
  usePostAccessMutation,
  useDeleteAccessMutation,
  usePatchAccessMutation,
  useGetRecordsMutation,
  usePostMergeMutation,
  useDeleteMergeMutation,
  useGetMergeMutation,
  useGetStatisticsQuery,
  useGetStatsQuery,
  useGetRelationshipsQuery,
} = externalCheckpointsApi;
