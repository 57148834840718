import { useMemo } from 'react';
import useAuth from './useAuth';
import useEvent from './useEvent';
import useTicketTypes from './useTicketTypes';

const useWufooFileds = () => {
  const { user, profile } = useAuth();
  const event = useEvent();
  const tickets = useTicketTypes();

  const getTicketInfo = (index, name) => {
    if (tickets && tickets[index]) {
      return tickets ? tickets[index][name] : '';
    }
    return '';
  };

  return useMemo(
    () => ({
      field6: event.name,
      field7: user.email,
      field3465: user.email,
      field3466: event.id,
      field130: `${profile?.first_name} ${profile?.last_name}`,
      field3006: getTicketInfo(0, 'name'),
      field136: getTicketInfo(0, 'price'),
      field3108: getTicketInfo(1, 'name'),
      field452: getTicketInfo(1, 'price'),
      field3209: getTicketInfo(2, 'name'),
      field455: getTicketInfo(2, 'price'),
      field3310: getTicketInfo(3, 'name'),
      field459: getTicketInfo(3, 'price'),
      field3311: getTicketInfo(4, 'name'),
      field462: getTicketInfo(4, 'price'),
    }),
    [user, event, profile],
  );
};

export default useWufooFileds;
