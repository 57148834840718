import { useSelector, useDispatch } from 'react-redux';
import {
  setAlerts,
  selectConfigAlerts,
  setOnBoarding,
  selectConfigOnBoarding,
  setTour,
  selectConfigTour,
  setFreezeNps,
  selectFreezeNps,
  setMerge,
  selectConfigMerge,
  setUnmerge,
  selectConfigUnmerge,
} from '../store/slices/config.slice';

const useLocalConfig = () => {
  const dispatch = useDispatch();
  const configAlerts = useSelector(selectConfigAlerts);
  const configOnBoarding = useSelector(selectConfigOnBoarding);
  const configTour = useSelector(selectConfigTour);
  const configFreezeNps = useSelector(selectFreezeNps);
  const configMerge = useSelector(selectConfigMerge);
  const configUnmerge = useSelector(selectConfigUnmerge);

  return {
    setAlerts: (data) => dispatch(setAlerts(data)),
    configAlerts,
    setOnBoarding: (data) => dispatch(setOnBoarding(data)),
    configOnBoarding,
    setTour: (data) => dispatch(setTour(data)),
    configTour,
    setFreezeNps: (data) => dispatch(setFreezeNps(data)),
    configFreezeNps,
    setMerge: (data) => dispatch(setMerge(data)),
    configMerge,
    setUnmerge: (data) => dispatch(setUnmerge(data)),
    configUnmerge,
  };
};

export default useLocalConfig;
