/* eslint-disable import/no-mutable-exports */
import React from 'react';
import { FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import * as yup from 'yup';
import { FormItem, InputMask, FormText } from '@boletia/blt-components';
import { code, currency } from '../configurations/states';

let schema = {};

function OnBoardingGeneral({ values }) {
  schema = {
    phone_number: yup
      .string()
      .min(10, 'El teléfono debe tener como mínimo 10 dígitos')
      .max(12, 'El teléfono debe tener como máximo 12 dígitos')
      .required('Ingresa tu número de teléfono'),
    website: yup
      .string()
      .nullable()
      .transform((value) => (value === '' ? null : value))
      .notRequired(),
    avg_capacity: yup.string().required('Debes seleccionar una opción'),
    avg_ticket_cost: yup.string().required('Ingresa un valor numérico'),
    country: yup.string().required('Debes seleccionar una opción'),
  };

  return (
    <Grid container spacing={2} sx={{ marginTop: '1px', marginBottom: '24px' }}>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel id="country">País</InputLabel>
          <FormItem
            name="country"
            Component={Select}
            componentProps={{
              fullWidth: true,
              label: 'País',
            }}
          >
            <MenuItem value="México">México</MenuItem>
            <MenuItem value="Estados Unidos">Estados Unidos</MenuItem>
            <MenuItem value="Colombia">Colombia</MenuItem>
            <MenuItem value="Guatemala">Guatemala</MenuItem>
            <MenuItem value="Argentina">Argentina</MenuItem>
            <MenuItem value="Chile">Chile</MenuItem>
            <MenuItem value="Perú">Perú</MenuItem>
            <MenuItem value="Otro">Otro</MenuItem>
          </FormItem>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormItem
          name="phone_number"
          labelDetail="(Opcional)"
          Component={InputMask}
          componentProps={{
            fullWidth: true,
            label: 'Número de celular',
            maskProps: {
              thousandSeparator: false,
              isNumericString: true,
              prefix: code[values.country] ? `${code[values.country]} | ` : '',
              mask: ' ',
              format: code[values.country]
                ? `${code[values.country]} | ### ### ####`
                : '### ### ####',
            },
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <FormText
          name="website"
          label="Enlace de página web o redes"
          labelDetail="(Opcional)"
          Component={InputMask}
          componentProps={{
            fullWidth: true,
            placeholder: 'Enlace de página web o redes',
            label: 'Enlace de página web o redes',
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel id="avg_capacity">Aforo</InputLabel>
          <FormItem
            name="avg_capacity"
            Component={Select}
            componentProps={{
              fullWidth: true,
              label: 'Aforo',
            }}
          >
            <MenuItem value="1 - 250">Entre 1 - 250 personas</MenuItem>
            <MenuItem value="250 - 1000">Entre 250 - 1000 personas</MenuItem>
            <MenuItem value="1000 - 3000">Entre 1000 - 3000 personas</MenuItem>
            <MenuItem value="3000 - 6000">Entre 3000 - 6000 personas</MenuItem>
            <MenuItem value="6000+">Más de 6000 personas</MenuItem>
          </FormItem>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormItem
          name="avg_ticket_cost"
          Component={InputMask}
          componentProps={{
            fullWidth: true,
            label: 'Costo promedio del evento',
            maskProps: {
              thousandSeparator: true,
              isNumericString: false,
              mask: ' ',
              prefix: '$ ',
              suffix: currency[values.country]
                ? ` ${currency[values.country]}`
                : '',
            },
          }}
          sublabel="Escribe el costo promedio de tu evento"
        />
      </Grid>
    </Grid>
  );
}
export default OnBoardingGeneral;
export { schema };
