import { createApi } from '@reduxjs/toolkit/query/react';
import { authBaseQuery } from '../utils/utils';

const prepareUrl = (state, endpoint, url) => {
  if (endpoint === 'postStatisticsGeneral') {
    return 'reporter/statistics-general';
  }

  const { id: eventID } = state.event;

  if (!eventID && !url?.id) {
    return {
      error: {
        status: 400,
        statusText: 'Bad Request',
        data: 'No event ID received',
      },
    };
  }
  return `reporter/statistics/events/${eventID || url.id}${
    url.query ? `?${url.query}` : `?${url}`
  }`;
};

export const eventStatisticsApi = createApi({
  reducerPath: 'eventStatisticsApi',
  baseQuery: authBaseQuery({
    baseUrl: process.env.REACT_APP_API_EVENT,
    prepareUrl,
  }),
  refetchOnMountOrArgChange: true,
  tagTypes: ['EventStatistics'],
  endpoints: (build) => ({
    getEventStatistics: build.query({
      query: (params) => ({
        url: params,
        method: 'GET',
      }),
    }),
    postStatisticsGeneral: build.mutation({
      query: ({ body = { 'load-events-and-sellers': true } }) => ({
        body,
        method: 'POST',
      }),
    }),
  }),
});

export const { useGetEventStatisticsQuery, usePostStatisticsGeneralMutation } =
  eventStatisticsApi;
