import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import CrownIcon from '../../modules/rspv/components/dashboard/assets/CrownIcon';
import useUserClient from '../../hooks/useUserClient';

export default function ExclusiveEvent() {
  const { client } = useUserClient();

  return (
    <Box
      sx={{
        border: '1px solid #8282FF',
        borderRadius: '12px',
        padding: '8px 0px',
        mt: client?.id ? 0 : 2,
      }}
    >
      <Stack alignItems="center" gap="8px">
        <CrownIcon />
        <Typography variant="subtitle2" color="primary">
          Evento exclusivo
        </Typography>
      </Stack>
    </Box>
  );
}
