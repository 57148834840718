import { PropTypes } from 'prop-types';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  FormItem,
  FormsWrapper,
  FormText,
} from '@boletia/blt-components/src/Forms';
import {
  Button,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@mui/material';
import { InputMask } from '@boletia/blt-components';
import useUserClient from '../../../../hooks/useUserClient';
import AccountAlert from './AccountAlert';

const schema = yup.object({
  bank_name: yup.string().required('Ingrese el nombre del banco').nullable(),
  clabe: yup
    .string()
    .min(18, 'La cuenta debe tener al menos 18 dígitos')
    .max(18, 'La cuenta debe tener al menos 18 dígitos')
    .required('La cuenta CLABE es obligatoria y debe ser numérica')
    .nullable(),
  account_number: yup
    .string()
    .min(10, 'La cuenta debe tener como mínimo 10 dígitos')
    .required('Ingrese su número de cuenta')
    .nullable(),
  cardholder: yup
    .string()
    .required('Ingrese el titular de la cuenta')
    .nullable(),
  rfc_bank: yup
    .string()
    .matches(
      /^[a-zA-Z&]{3,4}[0-9]{6}[a-zA-Z0-9]{3}$/,
      'Formato de RFC inválido',
    )
    .nullable()
    .required('El RFC es obligatorio'),
});

export default function BankForm({ value, onSubmit, hasRFC }) {
  const { client } = useUserClient();
  const formatValue = (d) => {
    const data = { ...d };

    if (d !== null && d !== undefined) {
      let radio = null;
      if (hasRFC) {
        data.rfc_bank = hasRFC;
      }
      if (data.rfc_bank !== null) {
        if (data.rfc_bank.length === 12) radio = 'moral';
        if (data.rfc_bank.length === 13) radio = 'fisica';
      }

      const newValues = {
        ...data,
        rfc_type: radio,
      };
      return { ...newValues };
    }
  };
  const transformRfcType = (v, setValue) => {
    if (
      v.length === 12 &&
      v.match(/^[a-zA-Z]{3,4}[0-9]{6,6}[a-zA-Z0-9]{3,3}$/)
    ) {
      setValue('rfc_type', 'moral');
    }
    if (
      v.length === 13 &&
      v.match(/^[a-zA-Z]{3,4}[0-9]{6,6}[a-zA-Z0-9]{3,3}$/)
    ) {
      setValue('rfc_type', 'fisica');
    }
    if (
      (v.length < 13 || v.length > 13) &&
      !v.match(/^[a-zA-Z]{3,4}[0-9]{6,6}[a-zA-Z0-9]{3,3}$/)
    ) {
      setValue('rfc_type', '');
    }
  };

  const transformAccountNumber = (acct, prev, name = '') => {
    if ((/^[0-9]+$/.test(acct) || acct === '')
      && acct.length <= (name === 'clabe' ? 18 : 16)) {
      return acct;
    }
    return prev;
  };

  const setDisable = () => {
    const { clabe, cardholder } = value;
    if (cardholder && value?.account_number && clabe && value?.rfc_bank) {
      return true;
    }
    return false;
  };

  setDisable();

  return (
    <FormsWrapper
      value={formatValue(value)}
      resolver={yupResolver(schema)}
      onSubmit={onSubmit}
    >
      <Grid item xs={12}>
        <AccountAlert open={setDisable()} />
      </Grid>
      <Grid container spacing={4}>
        <Grid item md={6} xs={12}>
          <FormText
            name="bank_name"
            label="Nombre del banco"
            disabled={client || setDisable()}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <FormText
            name="clabe"
            label="CLABE (18 dígitos)"
            disabled={client || setDisable()}
            transform={(e, data) =>
              transformAccountNumber(e.target.value, data.clabe, 'clabe')
            }
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <FormText
            name="account_number"
            label="Número de cuenta"
            disabled={client || setDisable()}
            transform={(e, data) =>
              transformAccountNumber(e.target.value, data.account_number)
            }
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <FormText
            name="cardholder"
            label="Nombre del tarjetahabiente"
            disabled={client || setDisable()}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <FormText
            name="rfc_bank"
            label="RFC, 12 caracteres para persona moral, 13 caracteres para persona física"
            transform={(v, data, setValue) => {
              const rfc = v.target.value;
              if (rfc) {
                transformRfcType(rfc, setValue);
              }
              return { ...v, target: { value: rfc.toUpperCase() } };
            }}
            disabled={hasRFC || client || setDisable()}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <FormItem
            name="rfc_type"
            label="Tipo de RFC"
            Component={RadioGroup}
            componentProps={{
              disabled: client || setDisable(),
              row: 'row',
              sx: {
                borderRadius: '10px',
                border: 'solid 1px #DEDDDB',
                padding: '8px 16px',
                display: 'flex',
                gap: '28px',
              },
            }}
          >
            <FormControlLabel
              value="fisica"
              disabled
              control={<Radio />}
              label="Persona física"
            />
            <FormControlLabel
              value="moral"
              disabled
              control={<Radio />}
              label="Persona moral"
            />
          </FormItem>
        </Grid>
        {!client && (
          <Grid
            item
            sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}
          >
            <Button
              type="submit"
              variant="contained"
              sx={{ width: { xs: '100%', md: 'auto' } }}
              size="medium"
              disabled={client || setDisable()}
            >
              Guardar Cambios
            </Button>
          </Grid>
        )}
      </Grid>
    </FormsWrapper>
  );
}

BankForm.propTypes = {
  value: PropTypes.object,
  onSubmit: PropTypes.func,
  hasRFC: PropTypes.string,
};
