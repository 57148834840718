import PropTypes from 'prop-types';
import { t } from 'i18next';
import { Button, Container, styled, Typography } from '@mui/material';
import { Logo, Page, useSettings } from '@boletia/blt-ui';
import { MaintenanceIllustration } from '@boletia/blt-ui/src/assets';

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  left: 0,
  lineHeight: 0,
  width: '100%',
  position: 'absolute',
  padding: theme.spacing(3, 3, 0),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(5, 5, 0),
  },
}));

export default function ErrorFallback({ error, resetError }) {
  const { themeStretch } = useSettings();

  return (
    <Page title={t('errors.errorFallback.page')}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <HeaderStyle>
          <Logo />
        </HeaderStyle>
        <ContentStyle sx={{ textAlign: 'center', alignItems: 'center' }}>
          <Typography variant="h3" paragraph>
            {t('errors.errorFallback.title')}
          </Typography>
          <Typography sx={{ color: 'text.secondary' }}>
            {t('errors.errorFallback.subtitle')}
          </Typography>
          <Typography variant="subtitle1" paragraph mt={1}>
            {`(${error?.cause?.message})`}
          </Typography>
          <MaintenanceIllustration sx={{ my: 10, height: 240 }} />
          <Button onClick={() => resetError()} variant="contained">
            {t('errors.errorFallback.refresh')}
          </Button>
        </ContentStyle>
      </Container>
    </Page>
  );
}

ErrorFallback.propTypes = {
  error: PropTypes.object,
  resetError: PropTypes.func,
};
