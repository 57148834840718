import React from 'react';

const FormattedMessage = ({ message }) => {
  const parts = message.split(/(<strong>.*?<\/strong>)/g);
  
  return (
    <>
      {parts.map((part, i) => {
        if (part.startsWith('<strong>')) {
          const text = part.replace(/<\/?strong>/g, '');
          return <strong key={i}>{text}</strong>;
        }
        return part;
      })}
    </>
  );
};

export default FormattedMessage;