import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import {
  Box,
  Link,
  Typography,
  Avatar,
  Stack,
  CircularProgress,
} from '@mui/material';
import { deepBluePreset } from '@boletia/blt-ui/src/utils/getColorPresets';
import useUserClient from '../../hooks/useUserClient';
import useAuth from '../../hooks/useAuth';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: '12px',
  backgroundColor: theme.palette.grey[500_12],
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
  }),
}));

// ----------------------------------------------------------------------

NavbarAccount.propTypes = {
  isCollapse: PropTypes.bool,
};

export default function NavbarAccount({ isCollapse }) {
  const [userProfile, setUserProfile] = useState({
    avatar: null,
    first_name: '',
    last_name: '',
    email: '',
  });
  const {
    profile,
    user: { id },
  } = useAuth();
  const { clientProfile, client } = useUserClient();

  useEffect(() => {
    if (clientProfile?.user_profile) {
      setUserProfile(clientProfile.user_profile);
    } else if (profile) {
      setUserProfile(profile);
    }
  }, [profile, clientProfile]);

  return (
    <Link underline="none" color="inherit">
      <RootStyle
        sx={{
          ...(isCollapse && {
            bgcolor: 'transparent',
          }),
          ...(client?.id && { borderRadius: '12px 12px 0 0' }),
        }}
      >
        {!userProfile.email ? (
          <Stack height={'66px'} justifyContent={'center'}>
            <CircularProgress />
          </Stack>
        ) : (
          <>
            <Avatar
              src={userProfile?.avatar}
              alt={`${userProfile?.first_name} ${userProfile?.last_name}`}
            />
            <Box
              sx={{
                ml: 2,
                transition: (theme) =>
                  theme.transitions.create('width', {
                    duration: theme.transitions.duration.shorter,
                  }),
                ...(isCollapse && {
                  ml: 0,
                  width: 0,
                }),
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              <Typography variant="subtitle2" noWrap>
                {`${userProfile?.first_name} ${userProfile?.last_name}`}
              </Typography>
              <Typography
                variant="body2"
                noWrap
                sx={{ color: 'text.secondary' }}
              >
                {userProfile?.email}
              </Typography>
              <Typography variant="body2" color={deepBluePreset.main}>
                ID: {client?.id || id}
              </Typography>
            </Box>
          </>
        )}
      </RootStyle>
    </Link>
  );
}
