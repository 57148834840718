import { Iconify } from '@boletia/blt-ui';
import { Box, Link, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router';

function HelpCenterButton() {
  const [show, setShow] = React.useState(false);

  const location = useLocation();

  useEffect(() => {
    setShow(!location.pathname.includes('auth'));
  }, [location]);

  return (
    <Link
      href="https://knowledge.boletia.com/knowledge/soy-organizador-de-eventos"
      target="_blank"
      rel="noreferrer noopener"
      sx={{
        'width': '60px',
        'height': '60px',
        'borderRadius': '50%',
        'position': 'fixed',
        'bottom': '15px',
        'right': !show ? '15px' : '90px',
        'zIndex': '9990',
        'color': '#fff',
        'backgroundImage':
          'linear-gradient(92.06deg, rgb(94, 106, 184) 42.78%, rgb(43, 122, 191) 100%);',
        'display': 'flex',
        'justifyContent': 'center',
        'alignItems': 'center',
        'transform': 'scale(1)',
        'transition': 'all 0.1s ease-in-out',
        '&:hover': {
          transform: 'scale(1.1)',
          transition: 'all 0.1s ease-in-out',
        },
      }}
    >
      <Box
        sx={{
          width: '60px',
          height: '60px',
          borderRadius: '50%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '32px',
            color: '#fff',
          }}
        >
          <Iconify icon="streamline:interface-help-question-circle-circle-faq-frame-help-info-mark-more-query-question" />
        </Typography>
      </Box>
    </Link>
  );
}

export default HelpCenterButton;
