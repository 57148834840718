export const rsvpLocales = {
  title: 'Links de acceso VIP',
  description:
    'Podrás configurar, secciones, tipos de boleto o todo tu evento. De esta manera solo a los asistentes que tu compartas el link puedan adquirir boletos. Por lanzamiento, crear estas ligas no generan ningún costo para ti.',
  welcome: {
    title: '¡Buenas noticias!',
    subtitle:
      'Hemos habilitado el servicio de links de acceso VIP para tu evento.',
    text1:
      'Podrás configurar, secciones, tipos de boleto o todo tu evento. De esta manera solo a los asistentes que tu compartas el link puedan adquirir boletos.',
    text2:
      'Por lanzamiento, crear estas ligas no generan ningún costo para ti.',
    buttonText: 'Entendido',
  },
  card: {
    static: 'Ligas para',
    title: 'tus Holds',
    text: 'Genera ligas VIP, que permitan a tus asistentes el acceso a ciertas secciones y tipos de boletos configurados desde tus apartados, para su compra.',
    buttonText: 'Crear ligas',
  },
  activateCard: {
    main: 'Haz tu evento Totalmente Exclusivo',
    description:
      ' (Tu evento no aparecerá en boletia y solo las personas con este link tendran acceso a tu evento.)',

    title: 'todo tu evento',
    text: 'Esta opción te permitirá crear links de compra para tus asistentes de cualquier tipo de boleto habilitado en tu Evento.',
    text2:
      '(Para utilizar esta opción es necesario convertir tu evento en exclusivo)',
    buttonText: 'Crear ligas',
    toggleText: 'Haz tu evento Totalmente Exclusivo',
    modal: {
      title: '¿Seguro que deseas hacer tu evento público?',
      text: 'Los links de acceso VIP se desactivarán, permitiendo que los asistentes encuentren tu evento en Boletia. Podrás volver a hacerlo exclusivo cuando quieras.',
    },
  },
  table: {
    title: 'Links creados',
    description: 'Aquí podrás encontrar los links de compra que hás generado',
    empty: 'Aún no haz generado ninguna liga',
    item: {
      section: 'Sección',
      tickets: 'Boletos',
      linksQty: 'No. de ligas',
      vigency: 'Ligas vigentes por',
      editButton: 'Editar',
      downloadButton: 'Descargar .xlsx',
      solds: 'Con compra: ',
      noSolds: 'Sin compra: ',
      notAvailable:
        'Los links para todo tu evento, estarán desactivados hasta que tu evento vuelva a ser exclusivo.',
    },
    actions: {
      changeVigency: 'Cambiar vigencia',
      showMeLinks: 'Muestrame los links:',
      downloadExcel: 'Descargar excel',
      showActive: 'Activos',
      showInactive: 'Deshabilitados',
      disableLinksButton: 'Desactivar links',
      enableLinksButton: 'Activar links',
      continueButton: 'Continuar',
      saveButton: 'Guardar',
      cancelButton: 'Cancelar',
      changeVigencySuccess: 'Se ha cambiado la vigencia correctamente',
      changeVigencyError: 'No se pudo cambiar la vigencia, intente más tarde',
      deleteLinkSuccess: 'Se ha eliminado el link correctamente',
      deleteLinkError: 'No se pudo eliminar el link, intente más tarde',
      modals: {
        updateStatus: {
          disable: {
            title: '¿Seguro que deseas desactivar los links?',
            text: 'Los links se desactivarán, por lo que los asistentes que los hayan recibido ya no podrán realizar compras en tu evento. Podrás reactivar tus links en cualquier momento.',
          },
          activate: {
            title: '¿Seguro que deseas activar los links?',
            text: 'Los links se activarán, por lo que los asistentes que los hayan recibido podrán realizar compras en tu evento. Podrás desactivar tus links en cualquier momento.',
          },
        },
        changeVigency: {
          title: 'La vigencia de tus links cambiará...',
          text: 'Quedarán activos del:',
        },
      },
    },
  },
  stepper: {
    continue: 'Continuar',
    cancel: 'Cancelar',
    loader: 'Generando...',
    finish: 'Finalizar',
    linkStep: {
      title: 'Creación de links',
      description:
        'Define el número de links a generar, vigencia, máximo de boletos por compra y nombre de tu agrupado.',
      labels: {
        linksQty: 'Número de ligas a generar:',
        vigency: 'Las ligas estarán vigente por: ',
        maxTickets: 'Max. de boletos por Compra:',
        name: 'Agrega un nombre para el grupo:',
      },
    },
    emptyStep: {
      startText: 'Aún no has generado holds. Dirigete a la sección',
      linkText: '"Holds y Cortesías"',
      endText: 'para configurar tus apartados.',
    },
    step1: {
      step: 'Paso 1',
      title: 'Elige un hold',
      totalHolds: 'Holds en total',
      description:
        'Selecciona un hold para comenzar con la creación de links de acceso VIP.',
    },
    step2: {
      step: 'Paso 2',
      title: 'Configura tus ligas',
      description:
        'Configura la cantidad de ligas, su vigencia y el máximo de boletos por compra.',
      seatsHoldeds: 'Asientos apartados:',
      ticketsHoldeds: 'Boletos apartados:',
      zone: 'Zona:',
      ticket: 'Boleto:',
      chooseText: 'Elige los tipos de boletos disponibles para comprar.',
      sections: {
        label: 'BOLETOS',
        chooseText: 'Elige los boletos que estarán activos en la compra.',
      },
      select: {
        all: 'Todos',
        full: 'Llenos',
        free: 'Libres',
        previous: 'Anteriores',
        recent: 'Recientes',
        placeholder: 'Filtrar holds',
      },
      placeholder: 'Buscar por nombre de tu hold',
    },
    step3: {
      step: 'Paso 3',
      title: 'Agrega un nombre',
      description:
        'Para finalizar agrega un nombre corto para identificar el agrupado donde estarán concentradas estas ligas.',
    },
    success: {
      title: '¡Tus links están listos!',
      description: 'Descargalos y empieza a compartirlos con tus asistentes.',
      section: 'Sección:',
      links: 'No. de links:',
      vigency: 'Links vigentes por:',
      createdAt: 'Creadas:',
      downloadButton: 'Descargar .xlsx',
      detailButton: 'Ver detalle',
      finishButton: 'Finalizar',
    },
    validators: {
      amountExcedeed: 'El número de links no puede ser mayor a 10',
      invalidNumber: 'Ingrese un valor',
    },
  },
  linksInfo: {
    resume: {
      section: 'Sección',
      tickets: 'Boletos',
      links: 'No. de links',
      purchased: 'Con compra',
      noPurchased: 'Sin compra',
      cancelled: 'Cancelados',
      vigency: 'Vigencia',
      preview: 'Vista previa',
    },
  },
  linksPage: {
    backToList: 'Regresar al listado',
    copyLink: 'Copiar link',
    linkCopied: 'Link copiado al portapapeles',
    linkNotCopied: 'No se pudo copiar el link al portapapeles',
    status: {
      active: 'Activo',
      desactive: 'Deshabilitado',
    },
  },
  multipleTickets: 'Múltiples boletos',
  noTickets: 'Sin boletos',
  multipleSections: 'Múltiples secciones',
  noSections: 'Sin secciones',
};
