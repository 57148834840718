import { createSlice } from '@reduxjs/toolkit';
import { setUser } from '@sentry/react';
import { reHydrateStore } from '../utils/local-storage';

const initialState = {
  user: null,
  token: null,
  roles: [],
  expiresIn: 0,
  idToken: null,
  refreshToken: null,
  tokenType: null,
};

const slice = createSlice({
  name: 'auth',
  initialState: reHydrateStore('auth') || initialState,
  reducers: {
    setCredentials: (state, { payload }) => payload,
  },
});

export const { setCredentials } = slice.actions;

export default slice.reducer;

export const selectCurrentUser = (state) => {
  setUser({
    id: state.auth.user?.id,
    email: state.auth.user?.email,
  });
  return state.auth.user;
};
export const selectAuth = (state) => state.auth;
