import { useState, useEffect } from 'react';
import { Modal } from '@boletia/blt-components';
import { Box, Button, Typography } from '@mui/material';
import { FormsWrapper } from '@boletia/blt-components/src/Forms';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Iconify } from '@boletia/blt-ui';
import OnBoardingGeneral, {
  schema as aboutSchema,
} from './OnBoardingGeneral';
import { useAddQuestionsMutation } from '../../../store/api/onBoarding.api';
import { code } from '../configurations/states';

function OnBoardingModal({ open, handleModal, user }) {
  const [changeForm, setChangeform] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [data, setData] = useState({});

  const [addQuestions] = useAddQuestionsMutation();

  const getMaxDate = () => {
    const actualDate = new Date();
    const years = actualDate.getFullYear() - 18;
    actualDate.setFullYear(years);
    return actualDate;
  };

  const submitQuestions = (questions) => {
    const {
      production_company: productionCompany,
      country,
      phone_number: phoneNumber,
      website,
      avg_ticket_cost: avgTicketCost,
      avg_capacity: avgCapacity,
    } = questions;

    const body = {
      user_detail: {
        production_company: productionCompany,
        country,
        phone_number: code[country] + phoneNumber,
      },
      event_detail: {
        avg_ticket_cost: avgTicketCost,
        avg_capacity: avgCapacity,
        website,
      },
    };
    console.log(body);
    addQuestions(body).catch((error) => console.error('Error:', error));
  };

  const schema = yup.object({
    ...aboutSchema,
  });

  const validatePartialData = (schemaValidator) => {
    schemaValidator
      .validate(data)
      .then(() => {
        setIsValid(true);
      })
      .catch((e) => {
        setIsValid(false);
      });
  };

  useEffect(() => {
    const schemaValidator = yup
      .object({
        ...aboutSchema,
      })
      .required();
    validatePartialData(schemaValidator);

  }, [data]);

  return (
    <Modal open={open} widthDefault="30%">
      <Box sx={{ padding: '40px' }}>
        <Iconify icon="ph:handshake" color='black' fontSize={64} />
        <Typography variant="h4">
          {'Queremos saber más de ti'}
        </Typography>

        <Typography
          variant="body2"
          align="inherit"
          sx={{ letterSpacing: '0.5px' }}
        >
          {'Necesitamos conocerte, por ello requerimos algunos datos.'}
        </Typography>

        <FormsWrapper
          value={{ birthday: getMaxDate() }}
          onChange={(d) => {
            setData(d);
          }}
          resolver={yupResolver(schema)}
          onSubmit={(d) => {
            handleModal();
            submitQuestions(d);
          }}
        >
          <OnBoardingGeneral values={data} />

          <Box
            sx={{ width: '100%', display: 'flex', justifyContent: { xs: 'center', md: 'flex-end' } }}
          >
            <Button
              sx={{ width: { xs: '70%', md: '50%' }, height: '50px' }}
              variant="contained"
              onClick={() => setChangeform(true)}
              disabled={!isValid}
              type={'submit'}
            >
              {'Enviar'}
            </Button>
          </Box>
        </FormsWrapper>
      </Box>
    </Modal>
  );
}

export default OnBoardingModal;