import PropTypes from 'prop-types';
import { createApi } from '@reduxjs/toolkit/query/react';
import { authBaseQuery } from '../utils/utils';

const prepareUrl = (state, _, url) => {
  const clientID = state?.config?.client?.id;
  const userID = state?.auth?.user?.id;

  if (clientID && url.includes(userID)) {
    return url.replace(userID, clientID);
  }

  return url;
};

export const boostApi = createApi({
  reducerPath: 'boostApi',
  baseQuery: authBaseQuery({
    baseUrl: process.env.REACT_APP_API_BOOST,
    prepareUrl,
  }),
  tagTypes: [
    'budget',
    'transactions',
    'campaigns',
    'campaign',
    'ad',
    'organizers',
  ],
  endpoints: (build) => ({
    // GET
    // useGetCampaignsQuery
    getCampaigns: build.query({
      query: ({
        userID,
        page,
        itemsPerPage,
        orderBy,
        filter,
        status,
        from,
        to,
      }) => ({
        url: `/boost/v1/${userID}`,
        params: {
          page,
          itemsPerPage,
          orderBy,
          status,
          filter,
          from,
          to,
        },
      }),
      providesTags: ['campaigns'],
    }),
    // useGetAllCampaignsQuery
    getAllCampaigns: build.query({
      query: ({ page, itemsPerPage, orderBy, filter, status, from, to }) => ({
        url: '/boost/v1/campaign',
        params: {
          page,
          itemsPerPage,
          orderBy,
          status,
          filter,
          from,
          to,
        },
        headers: {
          superadmin: true,
        },
      }),
      providesTags: ['campaigns'],
    }),
    // useGetCampaignQuery
    getCampaign: build.query({
      query: ({ userID, campaignID, superadmin = false }) => ({
        url: `/boost/v1/${userID}/campaign/${campaignID}`,
        method: 'GET',
        headers: {
          superadmin,
        },
      }),
      providesTags: ['campaign'],
    }),
    // useGetOrganizerCampaignsQuery
    getOrganizerCampaigns: build.query({
      query: ({ page, rowsPerPage, filter }) => ({
        url: `/boost/v1/organizers`,
        params: {
          page,
          itemsPerPage: rowsPerPage,
          filter,
        },
        headers: {
          superadmin: true,
        },
      }),
      providesTags: (result) => {
        if (!result) {
          return [{ type: 'organizers', id: 'LIST' }];
        }
        return [
          ...result?.items.map(({ id }) => ({ type: 'organizers', id })),
          { type: 'organizers', id: 'LIST' },
        ];
      },
    }),
    // useGetAdQuery
    getAd: build.query({
      query: ({ userID, campaignID, adID }) => ({
        url: `/boost/v1/${userID}/campaign/${campaignID}/ad/${adID}`,
        method: 'GET',
      }),
      providesTags: ['ad'],
    }),
    getBudget: build.query({
      query: (userID) => `/boost/v1/budget/${userID}`,
      providesTags: ['budget'],
    }),
    getTransactions: build.query({
      query: (userID) => `/boost/v1/transaction/${userID}`,
      providesTags: ['transactions'],
    }),
    // * get available events & seasons
    getAvailableEvents: build.query({
      query: (userID) => `/boost/v1/${userID}/events`,
    }),
    getAvailableSeasons: build.query({
      query: (userID) => `/boost/v1/${userID}/seasons`,
    }),
    //  POST
    // usePostSuggestedBudgetMutation
    postSuggestedBudget: build.mutation({
      query: ({ ownerID, ownerType }) => {
        validatePostSuggestedBudgetArgs({ ownerID, ownerType });
        return {
          url: '/boost/v1/campaign/suggested',
          method: 'POST',
          body: {
            owner_id: ownerID,
            owner_type: ownerType,
          },
        };
      },
      invalidatesTags: ['budget'],
    }),
    postImgUrl: build.mutation({
      query: ({ name = '' }) => ({
        url: `/boost/v1/campaign/img/url`,
        method: 'POST',
        body: {
          name,
        },
      }),
    }),
    postStripePI: build.mutation({
      query: ({ userID, amount, currency }) => ({
        url: `/boost/v1/pi/${userID}`,
        method: 'POST',
        body: {
          amount,
          currency,
        },
      }),
    }),
    postStripePIC: build.mutation({
      query: ({ sourceID, piID, userID }) => ({
        url: `/boost/v1/pic/${userID}`,
        method: 'POST',
        body: {
          source_id: sourceID,
          pi_id: piID,
        },
      }),
      invalidatesTags: ['budget'],
    }),
    postCampaign: build.mutation({
      query: ({
        userID,
        budgetExternal,
        endDate,
        name,
        ownerID,
        ownerName,
        ownerType,
        startDate,
      }) => {
        validatePostCampaignArgs({
          userID,
          budgetExternal,
          endDate,
          name,
          ownerID,
          ownerName,
          ownerType,
          startDate,
        });
        return {
          url: `/boost/v1/${userID}`,
          method: 'POST',
          body: {
            budget_external: budgetExternal,
            end_date: endDate,
            name,
            owner_id: ownerID,
            owner_name: ownerName,
            owner_type: ownerType,
            start_date: startDate,
          },
        };
      },
      invalidatesTags: ['campaigns'],
    }),
    postAd: build.mutation({
      query: ({
        userID,
        campaignID,
        description,
        actionCall,
        squareName,
        horizontalName,
        name,
      }) => {
        validatePostAdArgs({
          userID,
          campaignID,
          description,
          actionCall,
          squareName,
          horizontalName,
          name,
        });
        return {
          url: `/boost/v1/${userID}/campaign/${campaignID}`,
          method: 'POST',
          body: {
            description,
            cta: actionCall,
            key_image1: squareName,
            key_image2: horizontalName,
            name,
          },
        };
      },
      invalidatesTags: (_, error) => {
        if (!error) {
          return ['campaigns', 'budget'];
        }
        return [];
      },
    }),
    // Post - rechargeGMV
    // usePostRechargeGMVMutation
    postRechargeGMV: build.mutation({
      query: ({ userID, campaignID, description, amount }) => {
        validatePostRechargeGMVArgs({
          userID,
          campaignID,
          description,
          amount,
        });

        return {
          url: `/boost/v1/${userID}/campaign/${campaignID}/gmv`,
          method: 'POST',
          body: {
            description,
            amount,
          },
          headers: {
            superadmin: true,
          },
        };
      },
      invalidatesTags: (_, error) => {
        if (!error) {
          return ['campaigns', 'campaign'];
        }
        return [];
      },
    }),
    // usePostGMVInitMutation
    postGMVInit: build.mutation({
      query: ({ userID, campaignID }) => ({
        url: `/boost/v1/${userID}/campaign/${campaignID}/gmv/init`,
        method: 'POST',
      }),
      invalidatesTags: (_, error) => {
        if (!error) {
          return ['campaigns'];
        }
        return [];
      },
    }),
    // PUT
    // usePutCampaignMutation
    putCampaign: build.mutation({
      query: ({
        userID,
        campaignID,
        budgetExternal,
        endDate,
        name,
        ownerID,
        ownerName,
        ownerType,
        startDate,
      }) => {
        validatePostCampaignArgs({
          userID,
          campaignID,
          budgetExternal,
          endDate,
          name,
          ownerID,
          ownerName,
          ownerType,
          startDate,
        });
        return {
          url: `/boost/v1/${userID}/campaign/${campaignID}`,
          method: 'PUT',
          body: {
            budget_external: budgetExternal,
            end_date: endDate,
            name,
            owner_id: ownerID,
            owner_name: ownerName,
            owner_type: ownerType,
            start_date: startDate,
          },
        };
      },
      invalidatesTags: ['campaigns', 'campaign', 'ad', 'budget'],
    }),
    // usePutAdMutation
    putAd: build.mutation({
      query: ({
        userID,
        campaignID,
        adID,
        description,
        actionCall,
        squareName,
        horizontalName,
        name,
      }) => ({
        url: `/boost/v1/${userID}/campaign/${campaignID}/ad/${adID}`,
        method: 'PUT',
        body: {
          description,
          cta: actionCall,
          key_image1: squareName,
          key_image2: horizontalName,
          name,
        },
      }),
      invalidatesTags: (_, error) => {
        if (!error) {
          return ['ad', 'campaigns', 'campaign'];
        }
        return [];
      },
    }),
    // usePutCampaignStatusMutation
    putCampaignStatus: build.mutation({
      query: ({ userID, campaignID, status, message, superadmin }) => {
        validatePutCampaignStatusArgs({
          userID,
          campaignID,
          status,
          message,
          superadmin,
        });
        return {
          url: `/boost/v1/${userID}/campaign/${campaignID}/status`,
          method: 'PUT',
          body: {
            status,
            message,
          },
          headers: {
            superadmin,
          },
        };
      },
      invalidatesTags: (_, error) => {
        if (!error) {
          return ['campaigns', 'campaign'];
        }
        return [];
      },
    }),
    // usePutCampaignActionMutation
    putCampaignAction: build.mutation({
      query: ({ userID, campaignID, action }) => {
        validatePutCampaignActionArgs({ userID, campaignID, action });
        return {
          url: `/boost/v1/${userID}/campaign/${campaignID}/status/organizer`,
          method: 'PUT',
          body: {
            status: action,
          },
        };
      },
      invalidatesTags: (_, error) => {
        if (!error) {
          return ['campaigns', 'campaign'];
        }
        return [];
      },
    }),
    // usePutAdStatusMutation
    putAdStatus: build.mutation({
      query: ({ userID, campaignID, adID, status, message, superadmin }) => {
        validatePutAdStatusArgs({
          userID,
          campaignID,
          adID,
          status,
          message,
          superadmin,
        });
        return {
          url: `/boost/v1/${userID}/campaign/${campaignID}/ad/${adID}/status`,
          method: 'PUT',
          body: {
            status,
            message,
          },
          headers: {
            superadmin,
          },
        };
      },
      invalidatesTags: (_, error) => {
        if (!error) {
          return ['ad', 'campaigns', 'campaign'];
        }
        return [];
      },
    }),
    // usePutAdActionMutation
    putAdAction: build.mutation({
      query: ({ userID, campaignID, adID, action }) => {
        validatePutAdActionArgs({ userID, campaignID, adID, action });
        return {
          url: `/boost/v1/${userID}/campaign/${campaignID}/ad/${adID}/action`,
          method: 'PUT',
          body: {
            action,
          },
        };
      },
      invalidatesTags: (_, error) => {
        if (!error) {
          return ['ad', 'campaigns', 'campaign'];
        }
        return [];
      },
    }),
    // usePutWhitelistMutation
    // enable/disable whitelist
    putWhitelist: build.mutation({
      query: ({ userID, value }) => ({
        url: `/boost/v1/${userID}/organizers/${value ? 'enable' : 'disable'}`,
        method: 'PUT',
      }),
      invalidatesTags: (_, error, { id }) => {
        if (!error) {
          return [{ type: 'organizers', id }];
        }
        return [];
      },
    }),
    // usePutGMVMutation
    // enable/disable gmv
    putGMV: build.mutation({
      query: ({ userID, value }) => ({
        url: `/boost/v1/organizers/${userID}/gmv/${
          value ? 'enable' : 'disable'
        }`,
        method: 'PUT',
      }),
      invalidatesTags: (_, error, { id }) => {
        if (!error) {
          return [{ type: 'organizers', id }];
        }
        return [];
      },
    }),
    // DELETE
    deleteCampaign: build.mutation({
      query: ({ userID, campaignID }) => ({
        url: `/boost/v1/${userID}/campaign/${campaignID}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['campaigns'],
    }),
    deleteAd: build.mutation({
      query: ({ userID, campaignID, adID }) => ({
        url: `/boost/v1/${userID}/campaign/${campaignID}/ad/${adID}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['campaigns'],
    }),
  }),
});

export const {
  // GET
  useGetCampaignsQuery,
  useGetOrganizerCampaignsQuery,
  useGetAllCampaignsQuery,
  useGetCampaignQuery,
  useGetAdQuery,
  useGetBudgetQuery,
  useGetTransactionsQuery,
  useGetAvailableEventsQuery,
  useGetAvailableSeasonsQuery,
  // POST
  usePostSuggestedBudgetMutation,
  usePostImgUrlMutation,
  usePostStripePIMutation,
  usePostStripePICMutation,
  usePostCampaignMutation,
  usePostAdMutation,
  usePostRechargeGMVMutation,
  usePostGMVInitMutation,
  // PUT
  usePutCampaignMutation,
  usePutAdMutation,
  usePutCampaignStatusMutation,
  usePutCampaignActionMutation,
  usePutAdStatusMutation,
  usePutAdActionMutation,
  usePutWhitelistMutation,
  usePutGMVMutation,
  // DELETE
  useDeleteCampaignMutation,
  useDeleteAdMutation,
} = boostApi;

const postCampaignArgsTypes = {
  userID: PropTypes.number.isRequired,
  budgetExternal: PropTypes.number.isRequired,
  endDate: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  ownerID: PropTypes.number.isRequired,
  ownerName: PropTypes.string.isRequired,
  ownerType: PropTypes.oneOf(['Event', 'Season']).isRequired,
  startDate: PropTypes.string.isRequired,
};

const validatePostCampaignArgs = (args) => {
  PropTypes.checkPropTypes(postCampaignArgsTypes, args, 'prop', 'postCampaign');
};

const postAdArgsTypes = {
  userID: PropTypes.number.isRequired,
  campaignID: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  actionCall: PropTypes.string.isRequired,
  squareName: PropTypes.string.isRequired,
  horizontalName: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

const validatePostAdArgs = (args) => {
  PropTypes.checkPropTypes(postAdArgsTypes, args, 'prop', 'postAd');
};

const postSuggestedBudgetArgsTypes = {
  ownerID: PropTypes.number.isRequired,
  ownerType: PropTypes.oneOf(['Event', 'Season']).isRequired,
};

const validatePostSuggestedBudgetArgs = (args) => {
  PropTypes.checkPropTypes(
    postSuggestedBudgetArgsTypes,
    args,
    'prop',
    'postSuggestedBudget',
  );
};

const putCampaignActionArgsTypes = {
  userID: PropTypes.number.isRequired,
  campaignID: PropTypes.number.isRequired,
  action: PropTypes.oneOf(['paused', 'active']).isRequired,
};

const validatePutCampaignActionArgs = (args) => {
  PropTypes.checkPropTypes(
    putCampaignActionArgsTypes,
    args,
    'prop',
    'putCampaignAction',
  );
};

const putAdActionArgsTypes = {
  userID: PropTypes.number.isRequired,
  campaignID: PropTypes.number.isRequired,
  adID: PropTypes.number.isRequired,
  action: PropTypes.oneOf(['paused', 'active']).isRequired,
};

const validatePutAdActionArgs = (args) => {
  PropTypes.checkPropTypes(putAdActionArgsTypes, args, 'prop', 'putAdAction');
};

const putCampaignStatusArgsTypes = {
  userID: PropTypes.number.isRequired,
  campaignID: PropTypes.number.isRequired,
  status: PropTypes.oneOf(['approved', 'stopped', 'refused']).isRequired,
  message: PropTypes.string,
  superadmin: PropTypes.bool,
};

const validatePutCampaignStatusArgs = (args) => {
  PropTypes.checkPropTypes(
    putCampaignStatusArgsTypes,
    args,
    'prop',
    'putCampaignStatus',
  );
};

const putAdStatusArgsTypes = {
  userID: PropTypes.number.isRequired,
  campaignID: PropTypes.number.isRequired,
  adID: PropTypes.number.isRequired,
  status: PropTypes.oneOf(['approved', 'stopped', 'refused']).isRequired,
  message: PropTypes.string,
  superadmin: PropTypes.bool,
};

const validatePutAdStatusArgs = (args) => {
  PropTypes.checkPropTypes(putAdStatusArgsTypes, args, 'prop', 'putAdStatus');
};

const postRechargeGMVArgsTypes = {
  userID: PropTypes.string.isRequired,
  campaignID: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
};

const validatePostRechargeGMVArgs = (args) => {
  PropTypes.checkPropTypes(
    postRechargeGMVArgsTypes,
    args,
    'prop',
    'postRechargeGMV',
  );
};
