import React from 'react';

function CrownIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.68 19.1999H4.32C3.52608 19.1999 2.88 19.846 2.88 20.6399C2.88 21.4338 3.52608 22.0799 4.32 22.0799H19.68C20.4739 22.0799 21.12 21.4338 21.12 20.6399C21.12 19.846 20.4739 19.1999 19.68 19.1999ZM24 6.71992C24 7.70486 23.2512 8.50937 22.2951 8.61878L20.4461 18.2399H3.55392L1.70494 8.61878C0.748781 8.50937 0 7.70486 0 6.71992C0 5.66106 0.861141 4.79992 1.92 4.79992C2.97886 4.79992 3.84 5.66106 3.84 6.71992C3.84 7.4313 3.44639 8.04662 2.86945 8.37878C3.97922 11.0092 5.71969 12.6229 7.33252 12.477C9.13345 12.3302 10.6023 10.2009 11.4125 6.63831C10.3738 6.37525 9.60005 5.43925 9.60005 4.31992C9.60005 2.99608 10.6762 1.91992 12 1.91992C13.3239 1.91992 14.4 2.99608 14.4 4.31992C14.4 5.4393 13.6263 6.3753 12.5876 6.63831C13.3978 10.2009 14.8666 12.3302 16.6676 12.477C18.2871 12.6229 20.0199 11.0092 21.1306 8.37878C20.5537 8.04662 20.1601 7.43125 20.1601 6.71992C20.1601 5.66106 21.0212 4.79992 22.0801 4.79992C23.1389 4.79992 24 5.66106 24 6.71992Z"
        fill="url(#paint0_linear_334_12090)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_334_12090"
          x1="11.9997"
          y1="2.50584"
          x2="11.9997"
          y2="22.061"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D55DB8" />
          <stop offset="1" stopColor="#3D49CC" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default CrownIcon;
