import { createApi } from '@reduxjs/toolkit/query/react';
import { authBaseQuery } from '../utils/utils';

export const rsvpApi = createApi({
  reducerPath: 'rsvpApi',
  baseQuery: authBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_EVENT}/rsvp/v1`,
  }),
  tagTypes: ['RSVP', 'link-group'],
  keepUnusedDataFor: 3600,
  endpoints: (build) => ({
    getEventLinks: build.query({
      query: (eventId) => `/events/${eventId}/links`,
      providesTags: ['RSVP'],
    }),
    getLinkByGroupName: build.query({
      query: ({ eventId, body }) => ({
        url: `/events/${eventId}/links-by-name`,
        method: 'POST',
        body,
      }),
      providesTags: ['link-group'],
    }),
    getRsvpStatusWelcomeModal: build.query({
      query: () => `/users/knows`,
    }),
    addLinks: build.mutation({
      query: (body) => ({
        url: `/links`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['RSVP'],
    }),
    updateLinkRangeDate: build.mutation({
      query: ({ eventId, ...body }) => ({
        url: `/events/${eventId}/links`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['link-group'],
    }),
    updateLinkStatus: build.mutation({
      query: ({ eventId, ...body }) => ({
        url: `/events/${eventId}/link-status`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['link-group'],
    }),
    deleteLinks: build.mutation({
      query: ({ eventId, ...body }) => ({
        url: `/events/${eventId}/links`,
        method: 'DELETE',
        body,
      }),
      invalidatesTags: ['link-group'],
    }),
  }),
});

export const {
  useGetEventLinksQuery,
  useGetLinkByGroupNameQuery,
  useGetRsvpStatusWelcomeModalQuery,
  useAddLinksMutation,
  useUpdateLinkRangeDateMutation,
  useUpdateLinkStatusMutation,
  useDeleteLinksMutation,
  usePrefetch,
} = rsvpApi;
