import PropTypes from 'prop-types';
import { Image, Iconify } from "@boletia/blt-ui";
import { Box, Button, Modal, Typography, Stack, ListItem, ListItemAvatar, Avatar, ListItemText, List, Chip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import FormattedMessage from '../../components/FormattedMessage';
import useIncode from '../../hooks/useIncode';
import { useGetOnboardingMutation } from '../../store/api/incode.api';
import useEvent from '../../hooks/useEvent';

const CreateIncodeUserModal = ({ openModal, setOpenModal }) => {
  const { t } = useTranslation();
  const {
    setInterviewId,
    setIncodeToken,
    setShowIncodeModalValue,
    setIncodeEventId,
  } = useIncode();
  const [getOnboarding] = useGetOnboardingMutation();

  const { id } = useEvent();

  const fetchOnboardingUrl = async () => {
    const response = await getOnboarding().unwrap();
    return response;
  };

  const showIncodeMobileProcess = async () => {
    try {
      const { success, error, url, interviewId, token } =
        await fetchOnboardingUrl();
      if (success) {
        setIncodeEventId(id);
        setInterviewId(interviewId);
        setIncodeToken(token);
        setShowIncodeModalValue(false);
        window.location.replace(url);
      } else {
        console.log('error', error);
      }
    } catch (e) {
      console.log('e', e);
    }
  };

  return (
    <Modal
      open={openModal}
      onClose={() => {
        setOpenModal(false);
      }}
      sx={{
        '.MuiBackdrop-root': { background: 'rgba(0, 0, 0, 0.5)' },
      }}
    >
        <Box
          width={{xs: '70%', sm: '50%'}}
          minWidth={{ xs: '310px', sm: '500px', md: '300px', lg: '500px' }}
          style={{
            backgroundColor: '#fff',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: '16px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#000',
            textAlign: 'center',
            padding: 30,
          }}
        >
          <Box
            sx={{ position: 'absolute', top: 15, right: 15, cursor: 'pointer' }}
            borderRadius={50}
            bgcolor='#FAFBFC'
            width={40}
            height={40}
            display='flex'
            justifyContent='center'
            alignItems='center'
          >
            <Iconify icon="ph:x-circle" color="#617079" fontSize={24} onClick={() => setOpenModal(false)}/>
          </Box>
          <Stack
            direction={{xs: 'column', sm: 'row'}} 
            justifyContent='center'
            alignItems='center'
            gap={1}
            mb={{ xs: 3, sm: -1 }}
          >
            <Image
              disabledEffect
              visibleByDefault
              alt="empty content"
              src={'/assets/illustrations/face_recognition.png'}
              sx={{ 
                height: { xs: 120, sm: 140 },
                mb: { xs: -2, sm: 3 },
                mt: { xs: 0, sm: 4 },
              }}
            />
            <Box
              display='flex'
              flexDirection='column'
              alignItems={{xs: 'center', sm: 'flex-start'}}
              maxWidth={280} width='100%'
            >
              <Typography
                variant="body2"
                fontSize={18}
              >
                {t('incodeMessage.title')}
              </Typography>
              <Typography
                variant='caption'
                align='left'
                style={{
                  textAlign: { xs: 'center', sm: 'flex-start' },
                }}
                fontWeight={800}
                fontSize={{ xs: 18, sm: 34}}
                lineHeight={1}
              >
                {t('incodeMessage.subtitle')}
              </Typography>
            </Box>
          </Stack>
          <Stack flexDirection='column' gap={2}>
            <Stack sx={{ 
              width: '100%', 
              maxWidth: '800px',
              minWidth: '300px',
              bgcolor: 'background.paper',
              display: 'grid',
              gridTemplateColumns: {
                xs: '1fr',
                sm: 'repeat(2, 1fr)',
              },
              gap: { xs: 1, sm: 2 },
              color: '#485056',
            }}>
              <ListItem sx={{ marginBottom: { xs: -2, sm: -2 } }}>
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: 'rgba(0, 0, 232, 0.1)' }}>
                    <Iconify icon="ph:identification-card" color="#0000E8" fontSize={24} />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText 
                  secondary={
                    <Typography component="span" variant='body2' fontSize={12}>
                      <FormattedMessage message={t('incodeMessage.bulletPoints.first')} />
                    </Typography>
                  } 
                />
              </ListItem>

              <ListItem sx={{ marginBottom: { xs: -2, sm: -2 } }}>
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: 'rgba(0, 0, 232, 0.1)' }}>
                    <Iconify icon="ph:baseball-cap" color="#0000E8" fontSize={24} />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText 
                  secondary={
                    <Typography component="span" variant='body2' fontSize={12}>
                      <FormattedMessage message={t('incodeMessage.bulletPoints.second')} />
                    </Typography>
                  } 
                />
              </ListItem>

              <ListItem sx={{ marginBottom: { xs: -2, sm: -2} }}>
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: 'rgba(0, 0, 232, 0.1)' }}>
                    <Iconify icon="ph:sun" color="#0000E8" fontSize={24} />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText 
                  secondary={
                    <Typography component="span" variant='body2' fontSize={12}>
                      <FormattedMessage message={t('incodeMessage.bulletPoints.third')} />
                    </Typography>
                  } 
                />
              </ListItem>

              <ListItem sx={{ marginBottom: { xs: -2, sm: -2 } }}>
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: 'rgba(0, 0, 232, 0.1)' }}>
                    <Iconify icon="ph:scan-smiley" color="#0000E8" fontSize={24} />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText 
                  secondary={
                    <Typography component="span" variant='body2' fontSize={12}>
                      <FormattedMessage message={t('incodeMessage.bulletPoints.fourth')} />
                    </Typography>
                  } 
                />
              </ListItem>
            </Stack>
            <Stack flexDirection='column' alignItems='flex-start' ml={{ xs: 0, sm: 2 }} mt={{ xs: 2, sm: 1 }}>
              <Typography variant="body2" fontSize={12} color='#485056'>
                <FormattedMessage message={t('incodeMessage.footerMessage')} />
              </Typography>
            </Stack>
          </Stack>
          <Stack mt={{ xs: 2, sm: 4 }} mb={{ xs: 4, sm: 2 }} width='100%' justifyContent='center' alignItems='center'>
            <Box width='100%' maxWidth={380}>
              <Button
                fullWidth
                variant="contained"
                onClick={showIncodeMobileProcess}
                sx={{
                  bgcolor: '#0000E8',
                }}
              >
                {t('global.buttons.start')}
              </Button>
            </Box>
          </Stack>
        </Box>
    </Modal>
  );
};

CreateIncodeUserModal.propTypes = {
  openModal: PropTypes.bool.isRequired,
  setOpenModal: PropTypes.func.isRequired,
};

export default CreateIncodeUserModal;
