import { useEffect, useState } from 'react';
import useAuth from './useAuth';

function useOnboarding() {
  const { userProfile } = useAuth();

  const [openOnboarding, setOpenOnboarding] = useState(false);
  const [openTour, setOpenTour] = useState(false);

  const completeOnboarding = () => {
    setOpenOnboarding(false);
    setOpenTour(true);
  };

  const completeTour = () => {
    setOpenTour(false);
  };

  useEffect(() => {
    if (userProfile) {
      setOpenOnboarding(!userProfile?.user_profile?.onboarding);
    }
  }, [userProfile]);

  return { openOnboarding, completeOnboarding, openTour, completeTour };
}

export default useOnboarding;
